import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const urlHelper = component.getHelpers("url");
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();

  return (
    <div className="page-content">
      <div className="intro-slider-container desktop-banner">
        <div
          className="owl-carousel owl-simple owl-light owl-nav-inside"
          data-toggle="owl"
          data-owl-options='{"nav": false}'
        >
          <a
            href="/bzr"
            className="intro-slide"
            style={{
              backgroundImage: `url(${urlHelper.createAccountImageUrl(
                account,
                repository,
                "item",
                "gallery",
                "default",
                "slider_bzr"
              )}?1)`,
            }}
          >
            .
          </a>
        </div>
        {/* End .owl-carousel owl-simple */}

        <span className="slider-loader text-white"></span>
        {/* End .slider-loader */}
      </div>
      <div className="intro-slider-container mobile-banner">
        <div
          className="owl-carousel owl-simple owl-light owl-nav-inside"
          data-toggle="owl"
          data-owl-options='{"nav": false}'
        >
          <a
            href="/bzr"
            className="intro-slide"
            style={{
              backgroundImage: `url(${urlHelper.createAccountImageUrl(
                account,
                repository,
                "item",
                "gallery",
                "default",
                "slider_bzr_mobile"
              )})`,
            }}
          >
            .
          </a>
        </div>
        {/* End .owl-carousel owl-simple */}

        <span className="slider-loader text-white"></span>
        {/* End .slider-loader */}
      </div>
      <div className="container mt-3">
        <div className="about-text text-center">
          <div className="mb-2" style={{ fontSize: "22px", color: "#333" }}>
            Μοναδικές προσφορές σας περιμένουν στο{" "}
            <b>Casa di Patsi Stock House</b>, στη Μεταμόρφωση.
          </div>
          <div className="mb-2" style={{ fontSize: "22px", color: "#333" }}>
            Το Bazaar ανοίγει τις πόρτες του{" "}
            <b>
              Σάββατο 30 Σεπτεμβρίου & Κυριακή 1 Οκτωβρίου από τις 10.00 το πρωί
              μέχρι τις 6.00 το απογευμα
            </b>
            .
          </div>
          <div className="mb-2" style={{ fontSize: "22px", color: "#333" }}>
            Η Casa di Patsi σας προσκαλεί να γνωρίσετε από κοντά τόσο την
            απλότητα όσο και την ιδιαιτερότητα των επίπλων της σε τιμές και
            σχέδια που θα σας καταπλήξουν.
          </div>
          <div className="mb-2" style={{ fontSize: "22px", color: "#333" }}>
            <b>
              <a
                href="https://www.google.com/maps/place/CASA+DI+PATSI+Stockhouse/@38.0678071,23.7531027,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1a1f89df1a515:0xaed40861765ff4d9!8m2!3d38.0678116!4d23.7552822"
                rel="noreferrer"
                target="_blank"
                style={{
                  color: "#333",
                  "&:hover,&:focus": { color: "#f32039" },
                }}
              >
                <i className="icon-map-marker" style={{ marginRight: "8px" }} />
                Ζακύνθου 2 και Οδυσσέως, Μεταμόρφωση
              </a>
            </b>
          </div>
          <div className="mb-2" style={{ fontSize: "22px", color: "#333" }}>
            <b>
              <a
                style={{
                  color: "#333",
                  "&:hover,&:focus": { color: "#f32039" },
                }}
                href="tel:2102850233"
              >
                <i className="icon-phone" style={{ marginRight: "8px" }} />
                2102850233
              </a>
            </b>
          </div>
        </div>
        {/* End .row */}
      </div>
      {/* End .container */}
    </div>
  );
};

export default ExportDefault;
