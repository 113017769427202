import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Pages from "../Components/Pages";

const ExportDefault = (props) => {
  const { component } = props;

  return (
    component.isLoaded() && (
      <Router>
        <Switch>
          <Route exact path="/" component={Pages.Home} />
          <Route exact path="/about-us" component={Pages.Stockhouse} />
          <Route exact path="/furniture" component={Pages.Furniture} />
          <Route exact path="/contact-us" component={Pages.Contact} />
          <Route exact path="/faq" component={Pages.Faq} />
          <Route exact path="/category/:slug" component={Pages.Products} />
          <Route exact path="/product/:slug" component={Pages.Product} />
          <Route exact path="/wishlist" component={Pages.Wishlist} />
          <Route exact path="/results/:word" component={Pages.Results} />
          <Route exact path="/bzr" component={Pages.Bazaar} />
          <Route component={Pages.Redirect} />
        </Switch>
      </Router>
    )
  );
};

export default ExportDefault;
