import React from "react";
// import FacebookChat from "../../../Components/Widget/FacebookChat";
import GoogleAnalytics from "../../../Components/Widget/GoogleAnalytics";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();
  const closed = component.getHelpers("value").getValue("closed");

  return (
    <footer className="footer footer-2">
      {/* <FacebookChat /> */}
      <div className="icon-boxes-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div
                className="icon-box icon-box-side"
                style={{ height: "105px" }}
              >
                <span className="icon-box-icon text-dark">
                  <i className="icon-rocket"></i>
                </span>
                <div className="icon-box-content">
                  <h3 className="icon-box-title">Άμεση παράδοση</h3>
                  {/* End .icon-box-title */}
                  <p>Ετοιμοπαράδοτα προϊόντα</p>
                </div>
                {/* End .icon-box-content */}
              </div>
              {/* End .icon-box */}
            </div>
            {/* End .col-sm-6 col-lg-3 */}

            <div className="col-sm-6 col-lg-3">
              <div
                className="icon-box icon-box-side"
                style={{ height: "105px" }}
              >
                <span className="icon-box-icon text-dark">
                  <i className="icon-star-o"></i>
                </span>

                <div className="icon-box-content">
                  <h3 className="icon-box-title">Design outlet </h3>
                  {/* End .icon-box-title */}
                  <p>Επώνυμα brands σε προσφορές όλο το χρόνο</p>
                </div>
                {/* End .icon-box-content */}
              </div>
              {/* End .icon-box */}
            </div>
            {/* End .col-sm-6 col-lg-3 */}
            {closed !== "true" && (
              <div className="col-sm-6 col-lg-3">
                <div
                  className="icon-box icon-box-side"
                  style={{ height: "105px" }}
                >
                  <span className="icon-box-icon text-dark">
                    <i className="icon-info-circle"></i>
                  </span>

                  <div className="icon-box-content">
                    <h3 className="icon-box-title">
                      Κυριακή ανοιχτά <br />
                      10:00 - 14:30
                    </h3>
                  </div>
                </div>
              </div>
            )}
            {/* End .col-sm-6 col-lg-3 */}
            <div className="col-sm-6 col-lg-3">
              <div
                className="icon-box icon-box-side"
                style={{ height: "105px" }}
              >
                <span className="icon-box-icon text-dark">
                  <i className="icon-star"></i>
                </span>

                <div className="icon-box-content">
                  <h3 className="icon-box-title">Interior Design Service</h3>
                  {/* End .icon-box-title */}
                  <p>Συμβούλες εσωτερικής διακόσμησης</p>
                </div>
                {/* End .icon-box-content */}
              </div>
              {/* End .icon-box */}
            </div>
            {/* End .col-sm-6 col-lg-3 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </div>
      {/* End .icon-boxes-container */}

      <div
        className="footer-newsletter bg-image"
        style={{
          backgroundImage: `url(${urlHelper.createAccountImageUrl(
            account,
            repository,
            "item",
            "gallery",
            "default",
            "end"
          )})`,
        }}
      >
        <div className="container">
          <div className="heading text-center">
            <h3 className="title">Ενημερωτικό Δελτίο</h3>
            {/* End .title */}
            <p className="title-desc">
              Κάνε εγγραφή και μάθε πρώτος για τις προσφορές μας
            </p>
            {/* End .title-desc */}
          </div>
          {/* End .heading */}

          <div className="row">
            <div
              id="mc_embed_signup"
              className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
            >
              <form
                action="https://casadipatsi.us4.list-manage.com/subscribe/post?u=eedb0610666ee526462c8eab1&amp;id=de0edcf0ff"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <div className="input-group">
                  <input
                    type="email"
                    name="EMAIL"
                    className="form-control"
                    id="mce-EMAIL"
                    placeholder="Διεύθυνση email"
                    required
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                    >
                      <span>ΕΓΓΡΑΦΗ</span>
                      <i className="icon-long-arrow-right"></i>
                    </button>
                  </div>
                  {/* .End .input-group-append */}
                </div>
                {/* .End .input-group */}
              </form>
            </div>
            {/* End .col-sm-10 offset-sm-1 col-lg-6 offset-lg-3 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </div>
      {/* End .footer-newsletter bg-image */}

      <div className="footer-middle">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="widget widget-about">
                <img
                  src="/logo.png"
                  className="footer-logo"
                  alt="Casa Di Patsi Stockhouse"
                  title="Casa Di Patsi Stockhouse"
                  width="150"
                  height="50"
                />
                <h4
                  className="mb-2"
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: 300,
                    fontFamily: "Arial",
                    letterSpacing: 0,
                    color: "#777",
                    lineHeight: 2,
                  }}
                >
                  Η Casa di Patsi εξειδικεύεται στο σχεδιασμό και την παραγωγή
                  επίπλων, παράλληλα με την αντιπροσωπεία κορυφαίων διεθνών
                  οίκων στην Ελλάδα. Επί τριάντα χρόνια η επιχείρησή μας
                  διατηρεί τον οικογενειακό της χαρακτήρα, προσφέροντας premium,
                  εξατομικευμένη εξυπηρέτηση.
                </h4>

                <div className="widget-about-info">
                  <div className="row">
                    <div className="col-sm-6 col-md-4">
                      <span className="widget-about-title">
                        Έχετε ερώτηση; Καλέστε μας στο:
                      </span>
                      <a className="tel_number" href="tel:2102850233">
                        2102850233
                      </a>
                    </div>
                    {/* End .col-sm-6 */}
                  </div>
                  {/* End .row */}
                </div>
                {/* End .widget-about-info */}
              </div>
              {/* End .widget about-widget */}
            </div>
            {/* End .col-sm-12 col-lg-3 */}

            <div className="col-sm-4 col-lg-2">
              <div className="widget">
                <h4 className="widget-title">Πληροφορίες</h4>
                <ul className="widget-list">
                  <li>
                    <a href="/about-us">Προφίλ</a>
                  </li>
                  <li>
                    <a href="/contact-us">Επικοινωνία</a>
                  </li>
                  {/* <li>
                    <a href="/faq">Συχνές Ερωτήσεις</a>
                  </li>
                  */}
                </ul>
              </div>
            </div>
            {/* End .col-sm-4 col-lg-3 */}

            <div className="col-sm-4 col-lg-2">
              <div className="widget">
                <h4 className="widget-title">Casa Di Patsi</h4>
                {/* End .widget-title */}

                <ul className="widget-list">
                  <li>
                    <a
                      href="https://www.casadipatsi.gr/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      New Collection
                    </a>
                  </li>
                </ul>
                {/* End .widget-list */}
              </div>
              {/* End .widget */}
            </div>
            {/* End .col-sm-64 col-lg-3 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </div>
      {/* End .footer-middle */}

      <div className="footer-bottom">
        <div className="container">
          <div className="social-icons">
            <span className="social-label">Social Media</span>

            <a
              href="https://www.facebook.com/casadipatsi"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Facebook"
            >
              <i className="icon-facebook-f"></i>
            </a>
            <a
              href="https://twitter.com/CasadiPatsi"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-twitter"></i>
            </a>
            <a
              href="https://gr.pinterest.com/casadipatsi/_created/"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-pinterest"></i>
            </a>
            <a
              href="https://www.instagram.com/casadipatsi/"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-instagram"></i>
            </a>
          </div>
          {/* End .soial-icons */}
        </div>
        {/* End .container */}
      </div>
      {/* End .footer-bottom */}
      <GoogleAnalytics />
    </footer>
  );
};

export default ExportDefault;
